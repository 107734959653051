
import {
  ArchiveConsultantBankAccountMutation,
  ArchiveConsultantBankAccountMutationMutation,
  ArchiveConsultantPayoneerAccountMutation,
  ArchiveConsultantPayoneerAccountMutationMutation,
  ConsultantBankAccount,
  ConsultantPayoneerAccount,
  ConsultantProfile,
} from "@/gql"
import { Component, Prop, Vue } from "vue-property-decorator"
import BillingAccountForm from "@/components/forms/BillingAccountForm.vue"
import { RefetchQueryDescription } from "apollo-client/core/watchQueryOptions"

@Component({
  components: {
    BillingAccountForm,
  },
})
export default class BillingAccounts extends Vue {
  @Prop() readonly consultant!: ConsultantProfile
  @Prop() readonly queries?: RefetchQueryDescription

  showPaymentModal = false
  showDeleteDialog = false
  loading = false
  activeAccount: ConsultantBankAccount | ConsultantPayoneerAccount | null = null
  activeAccountName = null

  onDeleteBillingAccount() {
    this.loading = true

    const account = this.activeAccount
    if ((account as ConsultantPayoneerAccount).customerId) {
      this.archivePayoneerAccount(account!.id)
    } else {
      this.archiveBankAccount(account!.id)
    }
  }

  onEditBillingAccount(account: ConsultantBankAccount | ConsultantPayoneerAccount) {
    this.activeAccount = account
    this.showPaymentModal = true
  }

  async archivePayoneerAccount(id: string) {
    const result = await this.mutate<ArchiveConsultantPayoneerAccountMutationMutation>({
      mutation: ArchiveConsultantPayoneerAccountMutation,
      variables: { accountId: id },
      done: () => (this.loading = false),
      refetchQueries: this.queries,
    })

    if (result.data && !result.data.archiveConsultantPayoneerAccount.error) {
      this.addSuccess("Account removed successfully")
      this.showDeleteDialog = false
      this.activeAccount = null
    }
  }

  async archiveBankAccount(id: string) {
    const result = await this.mutate<ArchiveConsultantBankAccountMutationMutation>({
      mutation: ArchiveConsultantBankAccountMutation,
      variables: { accountId: id },
      refetchQueries: this.queries,
      done: () => (this.loading = false),
    })

    if (result.data && !result.data.archiveConsultantBankAccount.error) {
      this.addSuccess("Account removed successfully")
      this.showDeleteDialog = false
      this.activeAccount = null
    }
  }
}
