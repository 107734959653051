
import {
  Country,
  ConsultantBankAccount,
  ConsultantBillingAccountsQuery,
  ConsultantPayoneerAccount,
  ConsultantBillingAccountsQueryQuery,
  CreateConsultantBankAccountMutation,
  UpdateConsultantBankAccountMutation,
  CreateConsultantPayoneerAccountMutation,
  UpdateConsultantPayoneerAccountMutation,
  UpdateConsultantBankAccountMutationMutation,
  CreateConsultantBankAccountMutationMutation,
  CreateConsultantPayoneerAccountMutationMutation,
  UpdateConsultantPayoneerAccountMutationMutation,
} from "@/gql"
import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import CountrySelect from "@/components/widgets/input/CountrySelect.vue"

type BillingAccountFormType = {
  methodType: "Bank" | "Payoneer"
  bankName: string | null
  customerId: number | null
  registeredEmailAddress: string | null
  accountName: string | null
  accountNumber: string | null
  swiftCode: string | null
  routingNumber: string | null
  sortCode: string | null
  country: Country | null
  bankAddress: string | null
}

@Component({
  components: {
    CountrySelect,
  },
})
export default class BillingAccountForm extends Vue {
  @Prop() readonly account?: ConsultantPayoneerAccount | ConsultantBankAccount

  defaultForm: BillingAccountFormType = {
    methodType: "Payoneer",
    bankName: null,
    customerId: null,
    registeredEmailAddress: null,
    accountName: null,
    accountNumber: null,
    swiftCode: null,
    routingNumber: null,
    sortCode: null,
    country: null,
    bankAddress: null,
  }
  loading = false

  form = { ...this.defaultForm }

  get isUpdating(): boolean {
    return !!this.account
  }

  get isPayoneer(): boolean {
    return this.account?.__typename == "ConsultantPayoneerAccount"
  }

  get isBankAccount(): boolean {
    return this.account?.__typename == "ConsultantBankAccount"
  }

  async onSave(): Promise<void> {
    this.loading = true

    if (this.isUpdating) {
      if (this.isPayoneer) await this.updatePayoneerAccount()
      else if (this.isBankAccount) await this.updateBankAccount()

      return
    }

    switch (this.form.methodType) {
      case "Payoneer":
        await this.createPayoneerAccount()
        break

      case "Bank":
        await this.createBankAccount()
        break

      default:
        this.addError("This payment method isn't available")
        break
    }
  }

  async createPayoneerAccount() {
    // add a payoneer account
    const result = await this.mutate<CreateConsultantPayoneerAccountMutationMutation>({
      mutation: CreateConsultantPayoneerAccountMutation,
      variables: {
        customerId: this.form.customerId,
        emailAddress: this.form.registeredEmailAddress,
      },
      done: () => (this.loading = false),
      update: (cache, { data }) => {
        this.updateCache<ConsultantBillingAccountsQueryQuery>(
          cache,
          ConsultantBillingAccountsQuery,
          (cacheData) => {
            if (data?.createConsultantPayoneerAccount?.payoneerAccount) {
              cacheData?.consultantProfile?.consultantPayoneerAccounts.push(
                data?.createConsultantPayoneerAccount?.payoneerAccount
              )
            }

            return cacheData
          }
        )
      },
    })

    if (result.data?.createConsultantPayoneerAccount?.payoneerAccount) {
      this.addSuccess("Payoneer account added")
      this.reset()
    }
  }

  async createBankAccount() {
    // add a bank account
    const {
      bankName,
      swiftCode,
      sortCode,
      routingNumber,
      accountName,
      accountNumber,
      bankAddress,
    } = this.form

    const result = await this.mutate<CreateConsultantBankAccountMutationMutation>({
      mutation: CreateConsultantBankAccountMutation,
      variables: {
        bankDetails: {
          bankName,
          swiftCode,
          sortCode,
          routingNumber,
          accountName,
          accountNumber,
          bankAddress,
          countryId: this.form.country?.id,
        },
      },
      done: () => (this.loading = false),
      update: (cache, { data }) => {
        this.updateCache<ConsultantBillingAccountsQueryQuery>(
          cache,
          ConsultantBillingAccountsQuery,
          (cacheData) => {
            if (data?.createConsultantBankAccount?.bankAccount) {
              cacheData?.consultantProfile?.consultantBankAccounts.push(
                data?.createConsultantBankAccount?.bankAccount
              )
            }

            return cacheData
          }
        )
      },
    })

    if (result.data?.createConsultantBankAccount?.bankAccount) {
      this.addSuccess("Bank account added")
      this.reset()
    }
  }

  async updatePayoneerAccount(): Promise<void> {
    const result = await this.mutate<UpdateConsultantPayoneerAccountMutationMutation>({
      mutation: UpdateConsultantPayoneerAccountMutation,
      variables: {
        payoneerAccountId: this.account!.id,
        customerId: this.form.customerId,
        emailAddress: this.form.registeredEmailAddress,
      },
      done: () => (this.loading = false),
    })

    // success
    if (result.data && !result.data.updateConsultantPayoneerAccount.error) {
      this.addSuccess("Payoneer account updated")
      this.reset()
    }
  }

  async updateBankAccount(): Promise<void> {
    const {
      bankName,
      swiftCode,
      sortCode,
      routingNumber,
      accountName,
      accountNumber,
      bankAddress,
    } = this.form

    const result = await this.mutate<UpdateConsultantBankAccountMutationMutation>({
      mutation: UpdateConsultantBankAccountMutation,
      variables: {
        accountId: this.account!.id,
        bankDetails: {
          bankName,
          swiftCode,
          sortCode,
          routingNumber,
          accountName,
          accountNumber,
          bankAddress,
          countryId: this.form.country?.id,
        },
      },
      done: () => (this.loading = false),
    })

    // success
    if (result.data && !result.data.updateConsultantBankAccount.error) {
      this.addSuccess("Bank account updated")
      this.reset()
    }
  }

  @Watch("account", { immediate: true })
  onAccountChange() {
    if (this.account) {
      this.assignObjectVals(this.account, this.form, { emailAddress: "registeredEmailAddress" })

      if (this.isPayoneer) this.form.methodType = "Payoneer"
      else if (this.isBankAccount) this.form.methodType = "Bank"
    } else {
      this.form = { ...this.defaultForm }
      this.$refs.observer && (this.$refs.observer as any).reset()
    }
  }

  reset() {
    this.form = { ...this.defaultForm }
    this.$refs.observer && (this.$refs.observer as any).reset()
    this.$emit("save")
  }
}
